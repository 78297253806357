import React from "react"
import Layout from "../components/layout"
import { Script } from "gatsby"


const isBrowser = () => typeof window !== "undefined";

const COOKIEBOT_DECLARATION_WRAPPER_ID = "cookiebot-declaration-wrapper";

export function CookieDeclaration() {
  React.useEffect(() => {
    // Inject Cookiebot declaration
    if (isBrowser()) {
      const cookiebotCookieDeclaratioScript = document.createElement("script");
      cookiebotCookieDeclaratioScript.src =
        "https://consent.cookiebot.com/15587dc3-3227-4fe1-9cc2-8de99847d920/cd.js";
      cookiebotCookieDeclaratioScript.type = "text/javascript";
      cookiebotCookieDeclaratioScript.async = true;

      const cookiebotWrapperEl = document.getElementById(
        COOKIEBOT_DECLARATION_WRAPPER_ID
      );
      if (cookiebotWrapperEl) {
        cookiebotWrapperEl.appendChild(cookiebotCookieDeclaratioScript);
      }
    }
  }, []);

  return <div id={COOKIEBOT_DECLARATION_WRAPPER_ID}></div>;
}

const PrivacyPolicy = ({ }) => {
  return (
    <Layout
      title="Privacy policy"
      description=""
      keywords={[]}
    >
      <div style={{ maxWidth: "880px", margin: "0 auto", marginTop: "100px" }}> {/* <- Ikke pent, I know, men det får funke */}
        <h1>Personvernpolicy</h1>
        <CookieDeclaration />
        {/* 

        <p>Lambertseter Ur & Optikk er forpliktet til å beskytte personvernet til våre kunder. Denne personvernpolicyen forklarer hvilke personopplysninger vi samler inn, hvordan vi bruker dem og hvem vi deler dem med.</p>

        <h2>Hvilke personopplysninger samler vi inn?</h2>

        <p>Vi samler inn følgende personopplysninger fra våre kunder:</p>

        <ul>
          <li>Analysedata: Vi bruker Google Analytics til å samle inn analysedata om hvordan besøkende bruker nettstedet vårt. Disse dataene inkluderer informasjon som sidevisninger, sprettprosent og tid på nettstedet. Vi samler ikke inn noen personlig identifiserbar informasjon i våre analysedata.</li>
          <li>Annonsedata: Vi bruker Google Ads og Meta Ads til å målrette annonseringen vår til de riktige menneskene. Når du besøker nettstedet vårt, kan vi samle inn informasjon om interessene dine, for eksempel produktene du ser på og sidene du besøker. Denne informasjonen brukes til å vise deg relevante annonser på andre nettsteder.</li>
          <li>Bestillingsdata: Når du legger inn en bestilling på nettstedet vårt, samler vi inn navn, adresse, e-postadresse og telefonnummer. Denne informasjonen brukes til å behandle bestillingen din og kontakte deg hvis vi har spørsmål.</li>
        </ul>

        <h2>Hvordan bruker vi personopplysningene dine?</h2>

        <p>Vi bruker personopplysningene dine til følgende formål:</p>

        <ul>
          <li>Analyse: Vi bruker analysedata til å forstå hvordan besøkende bruker nettstedet vårt og til å forbedre nettstedet og tjenestene våre.</li>
          <li>Annonsering: Vi bruker annonsedata til å målrette annonseringen vår til de riktige menneskene.</li>
          <li>Ordrebehandling: Vi bruker bestillingsdata til å behandle bestillingene dine og kontakte deg hvis vi har spørsmål.</li>
        </ul>

        <h2>Hvem deler vi personopplysningene dine med?</h2>

        <p>Vi deler annonsedata med Google og Meta. Vi deler ikke personopplysningene dine med noen andre tredjeparter uten ditt samtykke.</p>

        <h2>Hvordan beskytter vi personopplysningene dine?</h2>

        <p>Vi lagrer personopplysningene dine i en sikker database. Vi tar passende sikkerhetstiltak for å beskytte personopplysningene dine mot uautorisert tilgang, bruk eller avsløring.</p>

        <h2>Hvor lenge beholder vi personopplysningene dine?</h2>

        <p>Vi beholder bestillingsdataene dine til de blir bedt om fjernet eller ikke lenger brukes. Vi beholder annonsedataene dine så lenge du fortsetter å bruke nettstedet vårt.</p>

        <h2>Dine rettigheter</h2>

        <p>Du har følgende rettigheter under EUs personvernforordning (GDPR):</p>

        <ul>
          <li>Retten til å få tilgang til personopplysningene dine</li>
          <li>Retten til å rette personopplysningene dine</li>
          <li>Retten til å slette personopplysningene dine</li>
          <li>Retten til å begrense behandlingen av personopplysningene dine</li>
          <li>Retten til å protestere mot behandlingen av personopplysningene dine</li>
          <li>Retten til dataportabilitet</li>
        </ul>

        <p>Hvis du ønsker å utøve noen av disse rettighetene, vennligst kontakt oss på post@luo.no</p>

        <h2>Endringer i denne personvernpolicyen</h2>

        <p>Vi kan oppdatere denne personvernpolicyen fra tid til annen. Hvis vi gjør noen endringer, vil vi legge ut den oppdaterte personvernpolicyen på nettstedet vårt.</p>

        <h2>Kontakt oss</h2>

        <p>Hvis du har spørsmål om denne personvernpolicyen, vennligst kontakt oss på post@luo.no</p>
         */}
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
